import {Component} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-upcomming-page',
    standalone: true,
    templateUrl: './upcomming-page.component.html',
    styleUrls: ['./upcomming-page.component.scss'],
    imports: [TranslateModule],
})
export class UpcommingPageComponent {
    public imgSrc: string = 'assets/img/surgeon_wash_hands_m.png';

    public constructor() {
        this.imgSrc =
            Math.random() > 0.5
                ? 'assets/img/surgeon_wash_hands_m.png'
                : 'assets/img/surgeon_wash_hands_w.png';
    }
}
